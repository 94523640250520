.calendar {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
.monthTitle {
  text-align: center;
  font-size: 30px;
  font-weight: 200;
  padding: 10px;
}
.monthTotal{
  text-align: center;
  font-size: 20px;
  padding: 10px;
}