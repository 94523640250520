/* TopNav.module.css */
.topNav {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #fff;
    border-bottom: solid 1px #cbd0dd;
  }
  
  .logoContainer {
    flex-grow: 1;
    padding: 20px;
  }
  
  .userInfo {
    display: flex;
    gap: 20px;
    align-items: center;
  }