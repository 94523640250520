/* Basic sidebar styling */
.sidebarNav {
  background-color: #fff; /* Dark shade for sidebar background */
  color: #333; /* Light text for readability */
  width: 150px; /* Adjust width as necessary */
  height: 100vh; /* Full-view height */
  padding: 20px; /* Padding around the sidebar */
  border-right: 1px solid #cbd0dd;
}

.sidebarNav ul {
  list-style: none; /* Removes bullet points from list */
  padding: 0;
  font-size: 15px;
}

.sidebarNav li {
  padding: 12px 20px; /* Adequate padding for touch targets */
  margin: 8px 0; /* Space between items */
}

.sidebarNav a {
  text-decoration: none; /* Removes underline from links */
  color: #333; /* Light color text */
  display: block; /* Makes the entire area clickable */
}

.sidebarNav a:hover, .sidebarNav a:focus {
  text-decoration: underline;
  color: #777; /* Ensure text stands out on hover/focus */
}

.sidebarNav .active {
  text-decoration: underline;
  color:#333;
}