.day {
    flex: 1;
    padding: 5px;
    margin: 1px;
    border: 1px solid #d0d0d0;
    background-color: #fff;
    color: #444;
    text-align: left;
    border-radius: 5px;
  }
  .dayMoney {
    flex: 1;
    padding: 0px;
    margin: 0px;
    text-align: left;
    border-radius: 5px;
  }
  .today {
    background-color: #d0e7ff; /* Light red background for today */
    color: #444; /* Black text color for contrast */
    border: 1px solid #d0d0d0; 
  }
  .paydiv {
    border: 1px solid #b8b8b8;
    border-radius: 5px;
    background-color: #f0f0f0;
    margin: 5px;
    padding: 5px;
  }
