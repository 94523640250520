.dayTitles {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
  
  .dayTitle {
    flex: 1;
    text-align: center;
    font-weight: bold;
  }
  