.week {
    display: flex;
    justify-content: space-between;
  }
  
  .blank {
    flex: 1;
    padding: 35px;
    margin: 1px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    text-align: center;
    border-radius: 5px;
  }
  .bigpaydiv {
    flex: 1;
    padding: 35px 5px;
    margin: 1px;
    border: 1px solid #d0d0d0;
    background-color: #f9f9f9;
    color: #444;
    text-align: center;
    border-radius: 5px;
  }
  .bigText {
    font-weight: 700;
  }