.dashTitle{
    font-size: 30px;
    font-weight: 200;
    padding: 10px;
}
.rowpad{
    padding: 5px 0px;
}
.widgetRow{
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Creates four columns */
    padding: 5px;
}
/* Media Query for screens smaller than 750px */
@media (max-width: 750px) {
    .dashboardContainer {
        grid-template-columns: repeat(2, 1fr); /* Switch to two columns */
    }
}
.dashwidget hr {
    border: none;
    width: 50%; /* Only takes up 50% of the container's width */
    margin: 10px auto; /* Centers the hr and adds vertical space */
    height: 1px;
    background-color: #afafaf;
}
.dashwidgetSecond hr {
    border: none;
    width: 100%; /* Only takes up 50% of the container's width */
    margin: 10px auto; /* Centers the hr and adds vertical space */
    height: 1px;
    background-color: #afafaf;
}
.dashwidget{
    text-align: center;
    padding: 20px;
    margin: 5px;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    background-color: #fff;
}
.dashwidgetSecond{
    text-align: left;
    padding: 20px;
    margin: 5px;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    background-color: #fff;
}
.widgetTitle{
    font-size: 20px;
}
.dashwidgetSecond .widgetTitle{
    font-size: 20px;
    text-align: left;
}
.widgetSubTitle{
    font-size:15px;
    color: #555;
}
.dashwidgetSecond .widgetSubTitle{
    text-align: left;
    font-size: 15px;
}
.widgetNumber{
    font-size: 35px;
}
.dashHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
}

.settingsButton {
    padding: 15px;
    background-color: white;
    color: #333;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.3s ease;
}

.settingsButton:hover {
    background-color: #d0d0d0;
}

