body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f7f7f7;
  font-family: 'Roboto', sans-serif;
  color: #333; /* Modern text color for better readability */
  overflow: hidden;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  font-size: 30px;
  font-weight: 200;
  padding: 10px, 0px;
}

h2 {
  font-size: 24px;
  font-weight: 200;
  padding: 8px, 0px;
}
/* Main content area, which includes sidebar and page content */
.mainContent {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.pageContentWrapper{
  flex: 1;
  overflow-y: auto;
}
/* Styling for the page content area */
.pageContent {
  padding: 20px;  
}

/* Ensure TopNav is fixed at the top */
.TopNav {
  position: sticky;
  top: 0;
  z-index: 1000;
  /* Add any other styling for TopNav */
}

/* Ensure SidebarNav is fixed on the left */
.SidebarNav {
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
  /* Add any other styling for SidebarNav */
}

input, select, textarea {
  padding: 10px;
  margin: 10px 0;
  border: 2px solid #ddd;
  border-radius: 5px;
  display: block;
  width: 95%;
}

.library-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.library-container-big {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
}

.library-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.library-items {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.library-item {
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.library-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.library-item-title {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
}

.library-item-metrics {
  display: flex;
  align-items: center;
}

.library-item-rating {
  margin-right: 15px;
  color: gold;
}

.library-item-downloads {
  display: flex;
  align-items: center;
}

.library-item-description {
  margin-bottom: 10px;
}

.library-item-actions {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-right: 10px;
}

.button-primary {
  background-color: #4CAF50;
  color: white;
}

.button-secondary {
  background-color: #008CBA;
  color: white;
}

.button-gray {
  background-color: #777;
  color: white;
}

.button-white {
  background-color: #fff;
  color: #333;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.about-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.about-header{
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.about-item {
  width: calc(33% - 10px);
  min-width: 250px;
  margin: 10px 0;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.about-item-content {
  flex: 1;
}

.about-item-header {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.about-image {
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.proPill {
  background-color: #4CAF50;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8em;
  font-weight: bold;
}

.freePill {
  background-color: #3498db;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.8em;
  font-weight: bold;
  display: inline-block;
  box-shadow: 0 2px 4px rgba(52, 152, 219, 0.3);
  transition: all 0.3s ease;
}

.pill-right-align {
  float: right;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
}
th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
th {
  background-color: #f2f2f2;
  font-weight: bold;
}
tr:nth-child(even) {
  background-color: #f9f9f9;
}
tr:hover {
  background-color: #f5f5f5;
}

.copy-btn {
  background-color: #808080;
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 3px;
  font-size: 12px;
}

.copy-btn:hover {
  background-color: #a8a8a8;
}

/* Reset margin and padding for the code block wrapper */
.code-block-wrapper {
  margin: 0; /* Remove excessive margin */
  padding: 0; /* Reset padding */
}

/* Style for the header above the code block */
.code-header {
  background-color: #333; /* Dark gray background */
  color: #ffffff;  /* White text */
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-family: 'Courier New', Courier, monospace;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-bottom: 0px; /* Ensure header sits on top of the code block */
  margin-left: 0;
  margin-right: 0;
}

pre.hljs{
  margin: 0;
  padding: 10px;
}

.hljs-punctuation {
  color: #d1d9e1 !important;
}
/* Reduce overall margins for the editor content */
.markdown-body {
  margin: 0;
  padding: 0;
}

.fixed {
  position: fixed;
}

.gantt-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.gantt-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.gantt-scroll-container {
  width: 100%;
  overflow-x: auto;
}

.zoom-controls {
  display: flex;
  gap: 0.5rem; 
  margin-left: auto;
}